<template>
  <div ref="express" class="main">
    <p class="myclass-title">
      <span class="title-text">我的快递</span>
      <span class="title-border"></span>
    </p>
    <div class="main_box">
      <div v-if="lists.length>0" class="content">
        <template v-for="(item,index) in lists">
          <div :key="index" class="express">
            <div class="express_top">
              <div class="express_date">
                <span>{{ item.print_time }}</span>
              </div>
              <div class="express_number">
                <span v-if="item.express_no==''">暂未发货</span>
                <span v-if="item.express_no">物流单号： {{ item.express_no }}</span>
              </div>
            </div>
            <div class="express_bot">
              <div class="bot_left">
                <img v-if="item.product_thumb" :src="item.product_thumb" onerror="javascript:this.src='https://newoss.zhulong.com/forum/202403/05/25/183325dsqrs53owrxld2lg.png'" alt="">
                <img v-if="!item.product_thumb" src="https://newoss.zhulong.com/forum/202403/05/25/183325dsqrs53owrxld2lg.png" alt="">
                <div class="left_msg">
                  <h1>{{ item.product_name }}</h1>
                  <span>共{{ item.quantity }}件商品 </span>
                  <span> {{ item.express_name }}</span>
                </div>
              </div>
              <div class="bot_right">
                <span v-if="item.express_status!==0" class="look" @click="doLookTrackingInfo(item)">查看物流</span>
                <!-- express_status：1、未发货  2、已发货 3、已结束 -->
                <!-- <span v-if="item.express_status==3" class="state">已收货</span> -->
                <span v-if="item.express_status==1" class="state" @click="doGetExpressAction(item)">确认收货</span>
              </div>
            </div>
          </div>
        </template>
        <el-pagination
          v-show="count>params.limit"
          class="page"
          background
          :total="count"
          layout="prev, pager, next"
          :page-size="params.limit"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <div v-else class="noDataBox">
        <img src="@/assets/image/kong.png" alt="">
        <p>您还没有快递哦~</p>
      </div>
    </div>
    <!-- <div class="main_bot">
      底部
    </div> -->
  </div>
</template>

<script>
import { getExpressList, confirmExpress } from '@/api/center.js'
import { Message } from 'element-ui'

export default {
  setup() {
  },
  data() {
    return {
      count: 0,
      lists: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },
  created() {
    this.getExpressListDatas()
  },
  mounted() {
  },
  methods: {
    // 快递列表
    getExpressListDatas() {
      getExpressList(this.params).then(res => {
        const result = res.result
        this.count = result.count
        this.lists = result.list
      }).catch(err => {
        console.log(err)
      })
    },
    doLookTrackingInfo(item) {
      console.log('查看物流 ' + item.id)
      this.$router.push({ path: '/tracking', query: { id: item.id }})
    },
    doGetExpressAction(item) {
      // 确认收货
      const param = {
        id: item.id
      }
      confirmExpress(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          Message({ message: '已确认收货', type: 'success' })
          this.params.page = 1
          this.getExpressListDatas()
        } else {
          const msg = res.msg
          Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getExpressListDatas()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-color: #fff;
  position: relative;
  padding: 0 20px 20px 20px;
  font-family: MicrosoftYaHei;
}
.top {
  width: 100%;
  height: 55px;
  padding-top: 30px;
  padding-left: 23px;
  line-height: 20px;
  font-size: 18px;
  text-align: left;
}
.top_border {
  position: absolute;
  left: 10px;
  width: 4px;
  height: 20px;
  background: #f04e4f;
}
.content {
  background-color: #fff;
  position: relative;
  padding: 15px 10px 0;
  min-height: 500px;
}
.express {
  width: 925px;
  height: 132px;
  display: block;
  margin-bottom: 21px;
  text-align: left;
	border: solid 1px #dddddd;
  .express_top {
    float: left;
    display: flex;
    width: 925px;
    height: 30px;
    background-color: #f7f7f7;
    position: relative;
    .express_date {
      width: 140px;
      margin-left: 16px;
      font-size: 12px;
      line-height: 30px;
      color: #999999;
    }
    .express_number {
      display: inline-block;
      width: 230px;
      line-height: 30px;
      margin-left: 10px;
      font-size: 12px;
      color: #999999;
    }
  }
  .express_bot {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    margin-top: 45px;
    width: 925px;
    img {
      display: inline-block;
      width: 69px;
      height: 69px;
    }
  }
  .bot_left {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .left_msg {
      width: 600px;
      margin-left: 20px;
      h1 {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0px;
        color: #444444;
        margin-bottom: 12px;
      }
      span {
        padding: auto;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0px;
        color: #999999;
      }
    }
  }
  .bot_right {
    display: inline-block;
    span {
      display: inline-block;
      width: 90px;
      height: 30px;
      border-radius: 15px;
      border: solid 1px #ee2e2e;
      cursor: pointer;
      color:#ef2d2e;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      margin-right: 15px;
    }
  }
}
.page {
  padding: 25px 0;
  margin: 0 auto;
}
.noDataBox {
  position: relative;
  padding: 6px 10px 0;
  min-height: 500px;
  width: 100%;
  min-height: 500px;
  img {
    margin-top: 140px;
    width: 140px;
    height: 140px;
  }
  p {
    margin-top: 35px;
    text-align: center;
    line-height: 20px;
  }
}
.main_bot {
  background-color: red;
}
</style>
